import { useContext } from "react";

import AuthContext from "../store/auth-context";
import Loading from "./Loading";

import styles from "./SuspenseFallback.module.css";

const SuspenseFallback = () => {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <div className={`${styles.suspense} ${!isLoggedIn ? styles["full-height"] : ""}`}>
      <Loading message="loading..." />
    </div>
  );
};

export default SuspenseFallback;
