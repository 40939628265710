import React, { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import jwtDecode from "jwt-decode";

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const initialToken = localStorage.getItem("token");
  const [token, setToken] = useState(initialToken);
  const userIsLoggedIn = !!token;

  const queryClient = useQueryClient();

  const loginHandler = (token) => {
    localStorage.setItem("token", token);
    setToken(token);
    queryClient.clear();
    return;
  };

  const logoutHandler = () => {
    setToken(null);
    queryClient.clear();
    localStorage.removeItem("token");
    return;
  };

  try {
    if (userIsLoggedIn) {
      const decoded = jwtDecode(token);
      const currentTime = new Date().getTime() / 1000;
      if (currentTime > decoded.exp) {
        logoutHandler();
      }
    }
  } catch (err) {
    logoutHandler();
  }

  return (
    <AuthContext.Provider
      value={{
        token: token,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
