import { Routes, Route, Navigate } from "react-router-dom";
import { useContext, lazy, Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "./store/auth-context";

import MainNavigation from "./pages/MainNavigation/MainNavigation";

import SuspenseFallback from "./UI/SuspenseFallback";

const Orders = lazy(() => import("./pages/Orders/Orders"));
const BuyForMe = lazy(() => import("./pages/BuyForMe/BuyForMe"));
const ParcelOwlAddress = lazy(() => import("./pages/ParcelOwlAddress/ParcelOwlAddress"));
const Account = lazy(() => import("./pages/Account/Account"));
const Checkout = lazy(() => import("./pages/Checkout/Checkout"));
const Support = lazy(() => import("./pages/Support/Support"));
const Login = lazy(() => import("./pages/LogIn/Login"));
const Signup = lazy(() => import("./pages/Signup/Signup"));
const Confirm = lazy(() => import("./pages/Confirm/Confirm"));
const Reject = lazy(() => import("./pages/Reject/Reject"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword/ResetPassword"));
const Terms = lazy(() => import("./pages/Terms/Terms"));
const Pharmaceuticals = lazy(() => import("./pages/Pharmaceuticals/Pharmaceuticals"));

function App() {
  const { isLoggedIn } = useContext(AuthContext);
  const { i18n } = useTranslation();

  useEffect(() => {
    document.querySelector("html").dir = i18n.dir();

    const LTRHREF = "https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/css/bootstrap.min.css";
    const LTRINTEGRITY = "sha384-gH2yIJqKdNHPEq0n4Mqa/HGKIhSkIHeL5AyhkYV8i59U5AR6csBvApHHNl/vI1Bx";
    const RTLHREF = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.rtl.min.css";
    const RTLINTEGRITY = "sha384-T5m5WERuXcjgzF8DAb7tRkByEZQGcpraRTinjpywg37AO96WoYN9+hrhDVoM6CaT";

    document.querySelector("link[data-bs-stylesheet]").integrity =
      document.querySelector("html").dir === "ltr" ? LTRINTEGRITY : RTLINTEGRITY;
    document.querySelector("link[data-bs-stylesheet]").href = document.body.dir === "ltr" ? LTRHREF : RTLHREF;
  }, [i18n]);

  return (
    <Routes>
      <Route path="/terms-of-service" element={<Terms />} />
      {!isLoggedIn && (
        <>
          <Route
            path="/login"
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="/signup"
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <Signup />
              </Suspense>
            }
          />
          <Route
            path="/reset-password/:userId/:resetPasswordToken"
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <ResetPassword />
              </Suspense>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <ForgotPassword />
              </Suspense>
            }
          />
          {/* <Route
            path="/reject/:confirmationCode"
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <Reject />
              </Suspense>
            }
          /> */}
          <Route
            path="/confirm/:confirmationCode"
            element={
              <Suspense fallback={<SuspenseFallback />}>
                <Confirm />
              </Suspense>
            }
          />
          <Route path="*" element={<Navigate to={"/login"} />} />
        </>
      )}
      {isLoggedIn && (
        <Route element={<MainNavigation />}>
          <Route path="/" element={<Navigate to={"orders"} />} />
          <Route path="buy-for-me/*" element={<BuyForMe />} />
          <Route path="my-parcelowl-address" element={<ParcelOwlAddress />} />
          <Route path="orders" element={<Orders />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="pharmaceuticals" element={<Pharmaceuticals />} />
          <Route path="account" element={<Account />} />
          <Route path="support/*" element={<Support />} />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Route>
      )}
    </Routes>
  );
}

export default App;
