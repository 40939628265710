import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { QueryClientProvider, QueryClient, QueryCache } from "react-query";

import { ToastContainer, Slide } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AuthProvider } from "./store/auth-context";
import Loading from "./UI/Loading";

import { BrowserRouter } from "react-router-dom";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  // reference to the resource explaining where to put code for showing error messages from the server
  // : https://tkdodo.eu/blog/react-query-error-handling#the-global-callbacks
  queryCache: new QueryCache({
    onError: (error) => toast.warn(error?.message),
  }),
});

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "ar"],
    fallbackLng: "en",
    detection: {
      order: ["querystring", "localStorage"],
      caches: ["localStorage"],
      lookupLocalStorage: "lang",
      lookupQuerystring: "lang",
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}.json",
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthProvider>
      </QueryClientProvider>
      <ToastContainer transition={Slide} />
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
