import { Outlet, NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, Suspense } from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";

import SuspenseFallback from "../../UI/SuspenseFallback";

import useCreateQueryFunction from "../../hooks/useCreateQueryFunction";

import AuthContext from "../../store/auth-context";

import logo from "../../assets/images/Parcelowl-logo-6.svg";

import styles from "./MainNavigation.module.css";

const MainNavigation = () => {
  const { t, i18n } = useTranslation();

  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const themePreference = JSON.parse(localStorage.getItem("prefersDarkMode"));
  const [themeIsDark, setDarkTheme] = useState(themePreference);

  const themeHandler = () => {
    setDarkTheme((prevDarkThemeState) => !prevDarkThemeState);
  };

  const { logout } = useContext(AuthContext);

  useEffect(() => {
    if (themeIsDark) {
      document.body.classList.add("dark");
      localStorage.setItem("prefersDarkMode", "true");
    } else {
      document.body.classList.remove("dark");
      localStorage.setItem("prefersDarkMode", "false");
    }
  }, [themeIsDark]);

  const sidebarOpenHandler = () => {
    setSidebarIsOpen((prevIsOpenState) => !prevIsOpenState);
  };

  const activeNav = (navData) => (navData.isActive ? styles["active-nav"] : "");

  const navLinkOnTouchStartHandler = () => {
    setSidebarIsOpen(false);
  };

  const navigate = useNavigate();
  const navigateToCheckoutHandler = () => navigate("/checkout");

  const getCartDataReqConfig = { url: `${process.env.REACT_APP_API_URL}/cart` };
  const getCartData = useCreateQueryFunction(getCartDataReqConfig);
  const { data: cartData } = useQuery("cartData", getCartData, { staleTime: Infinity });

  const getAccountDataRequestConfig = { url: `${process.env.REACT_APP_API_URL}/user` };
  const getUserData = useCreateQueryFunction(getAccountDataRequestConfig);
  const { data: userInfo } = useQuery("userData", getUserData, { staleTime: 3600000, retry: 1 });

  const LTRHREF = "https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/css/bootstrap.min.css";
  const LTRINTEGRITY = "sha384-gH2yIJqKdNHPEq0n4Mqa/HGKIhSkIHeL5AyhkYV8i59U5AR6csBvApHHNl/vI1Bx";
  const RTLHREF = "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.rtl.min.css";
  const RTLINTEGRITY = "sha384-T5m5WERuXcjgzF8DAb7tRkByEZQGcpraRTinjpywg37AO96WoYN9+hrhDVoM6CaT";

  return (
    <>
      <nav
        className={`${styles.sidebar} ${sidebarIsOpen ? "" : styles.close} ${
          i18n.language === "ar" && styles["sidebar-ar"]
        }`}
      >
        <header>
          <div className={styles.logo}>
            <span className={styles.image}>
              <img src={logo} alt="logo" />
            </span>

            <div className={`${styles.text} ${styles["logo-text"]}`}>
              <span className={styles.name}>ParcelOwl</span>
              <span className={styles.profession}>Shop and Ship</span>
            </div>
            <button
              onClick={sidebarOpenHandler}
              className={`${styles["mobile-toggle"]} ${i18n.language === "ar" && styles["mobile-toggle-ar"]}`}
            >
              <i className="fa-solid fa-circle-xmark"></i>
            </button>
          </div>
          <button
            onClick={sidebarOpenHandler}
            className={`${styles.toggle} ${i18n.language === "ar" && styles["toggle-ar"]}`}
          >
            <i className={`fa-solid fa-chevron-${i18n.language === "ar" ? "left" : "right"} fa-justify-center`} />
          </button>
        </header>
        <div className={styles["menu-bar"]}>
          <div className={styles.menu}>
            <ul className={styles["menu-links"]}>
              <li className={styles["nav-link"]} onTouchStart={navLinkOnTouchStartHandler}>
                <NavLink to={"/orders"} className={activeNav}>
                  <i className={`fa-fw fa-solid fa-box ${styles.icon}`}></i>
                  <span className={`${styles.text} ${styles["nav-text"]}`}>{t("orders")}</span>
                </NavLink>
              </li>
              <li className={styles["nav-link"]} onTouchStart={navLinkOnTouchStartHandler}>
                <NavLink to={"/buy-for-me/products"} className={activeNav}>
                  <i className={`fa-fw fa-solid fa-shopping-bag ${styles.icon}`}></i>
                  <span className={`${styles.text} ${styles["nav-text"]}`}>{t("buy_for_me")}</span>
                </NavLink>
              </li>

              <li className={styles["nav-link"]} onTouchStart={navLinkOnTouchStartHandler}>
                <NavLink to={"/my-parcelowl-address"} className={activeNav}>
                  <i className={`fa-fw fa-solid fa-location-dot ${styles.icon}`}></i>
                  <span className={`${styles.text} ${styles["nav-text"]}`}>{t("my_address")}</span>
                </NavLink>
              </li>

              <li className={styles["nav-link"]} onTouchStart={navLinkOnTouchStartHandler}>
                <NavLink to={"/account"} className={activeNav}>
                  <i className={`fa-fw fa-solid fa-user ${styles.icon}`}></i>
                  <span className={`${styles.text} ${styles["nav-text"]}`}>{t("account")}</span>
                </NavLink>
              </li>

              <li className={styles["nav-link"]} onTouchStart={navLinkOnTouchStartHandler}>
                <NavLink to={"/support"} className={activeNav}>
                  <i className={`fa-fw fa-solid fa-life-ring ${styles.icon}`}></i>
                  <span className={`${styles.text} ${styles["nav-text"]}`}>{t("support")}</span>
                </NavLink>
              </li>
            </ul>
          </div>
          <div className={styles["bottom-content"]}>
            <li className={styles.langs}>
              <i className={`fa-solid fa-globe ${styles.icon}`} />
              <div>
                <button
                  className={i18n.language === "en" ? styles["active-lang"] : undefined}
                  onClick={() =>
                    i18n.changeLanguage("en").then(() => {
                      document.dir = "ltr";
                      document.querySelector("link[data-bs-stylesheet]").integrity = LTRINTEGRITY;
                      document.querySelector("link[data-bs-stylesheet]").href = LTRHREF;
                    })
                  }
                >
                  En
                </button>
                <button
                  className={i18n.language === "ar" ? styles["active-lang"] : undefined}
                  onClick={() =>
                    i18n.changeLanguage("ar").then(() => {
                      document.dir = "rtl";
                      document.querySelector("link[data-bs-stylesheet]").integrity = RTLINTEGRITY;
                      document.querySelector("link[data-bs-stylesheet]").href = RTLHREF;
                    })
                  }
                >
                  ع
                </button>
                {/* <button className={i18n.language === "ru" && "active-lang"} onClick={() => i18n.changeLanguage("ru")}>py</button> */}
              </div>
            </li>
            <li className="">
              <button className={styles.logoutbtn} type="button" onClick={logout}>
                {/* prettier-ignore */}
                <i className={`fa-fw fa-solid fa-right-from-bracket ${i18n.language !== "ar" && "fa-flip-horizontal"} ${styles.icon}`}></i>
                <span className={`${styles.text} ${styles["nav-text"]}`}>{t("logout")}</span>
              </button>
            </li>

            <li className={styles.mode} onClick={themeHandler}>
              {themeIsDark && <i className={`fa-fw fa-solid fa-sun ${styles.icon} sun`}></i>}
              {!themeIsDark && <i className={`fa-fw fa-solid fa-moon ${styles.icon} moon`}></i>}
              <span className={`${styles["mode-text"]} ${styles.text}`}>
                {themeIsDark ? t("light_mode") : t("dark_mode")}
              </span>
            </li>
          </div>
        </div>
      </nav>

      <main>
        <section className={`${styles.home} ${i18n.language === "ar" && styles["home-ar"]}`}>
          <div className={styles["content-wrapper"]}>
            <div className={styles.navbar}>
              <button className={styles.hamburger} onClick={sidebarOpenHandler}>
                <i className="fa-solid fa-bars"></i>
              </button>
              <div className={styles.logo}>
                <img src={logo} alt="logo" />
              </div>
              <div className={styles["welcome"]}>
                <span>
                  {t("hiya")}, {userInfo?.userData?.name} 👋😊
                </span>
              </div>
              <div className={styles.cart} onClick={navigateToCheckoutHandler}>
                <i
                  className={`fa-solid fa-shopping-cart ${i18n.language === "ar" && "fa-flip-horizontal"}`}
                  style={{ fontSize: "1.4rem" }}
                />
                <span>{cartData?.cart.length}</span>
              </div>
            </div>
            <div className={styles["main-content"]}>
              <Suspense fallback={<SuspenseFallback />}>
                <Outlet />
              </Suspense>
            </div>
          </div>
          <footer dir="ltr">
            <p>© {new Date().getFullYear().toString()} ParcelOwl. All rights reserved. </p>
          </footer>
        </section>
      </main>
    </>
  );
};

export default MainNavigation;
