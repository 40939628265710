import { useContext } from "react";

import AuthContext from "../store/auth-context";

const useCreateQueryFunction = (requestConfig) => {
  const { isLoggedIn, token } = useContext(AuthContext);

  return async () => {
    const response = await fetch(requestConfig.url, {
      headers: {
        "Content-Type": "application/json",
        ...(isLoggedIn && { Authorization: token }),
        ...requestConfig.headers,
      },
    });

    const data = await response.json();

    if (!response.ok) {
      throw data;
    }

    return data;
  };
};

export default useCreateQueryFunction;
