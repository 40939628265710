import styles from "./Loading.module.css";

const Loading = ({ message }) => {
  return (
    <div className={`${styles.loading}`}>
      <p>
        {message && <span className={styles.message}>{message}</span>}
        <span className={styles["loading-spinner"]}></span>
      </p>
    </div>
  );
};

export default Loading;
